<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ dark: !lightMode }"
        class="terroirs-slider"
    >
        <text-wipe id="terroirs" :color="shaderColor" :header="header" />
        <div class="slide-left-terr" v-observer>
            <div id="draggable" class="draggable">
                <div
                    id="draggable-slider-terroir-layout2"
                    class="draggable-slider unselectable"
                >
                    <div
                        v-for="(item, index) in terroirSlides"
                        :key="index"
                        :style="{ background: item.color }"
                        class="slide-item unselectable"
                    >
                        <div class="image-container">
                            <img
                                :src="item.path"
                                @mouseenter="mouseEnter"
                                @mouseleave="mouseLeave"
                                class="item-image unselectable"
                                alt=""
                                @click="routeChange(item.url)"
                            />
                        </div>
                        <span
                            :style="{ textDecorationColor: item.lineColor }"
                            class="title"
                            >{{ item.title }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import drag from "@/scripts/draggableSlider";
import textWipe from "@/components/custom/textWipe";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        textWipe
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        terroirSlides() {
            return this.data?.list?.map(item => ({
                path: item?.image?.[0]?.devices?.[this.device?.type],
                color: item?.color,
                lineColor: item.line_color || "#E5B676",
                title: item?.title,
                url: item?.url_link
            }));
        },
        header() {
            return {
                title: this.config?.header?.title
            };
        },
        shaderColor() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        }
    },
    methods: {
        routeChange(url) {
            if (url !== undefined) {
                window.location = url;
            }
        },
        getPadding(width) {
            let padding = 195;
            if (width <= 1650) {
                padding = 165;
            }
            if (width <= 1439) {
                padding = 128;
            }
            if (width <= 1365) {
                padding = 47;
            }
            if (width <= 767) {
                padding = 16;
            }
            return padding;
        },
        slideAnimation() {},
        mouseEnter(ev) {
            this.gsap.to(ev.target, {
                scale: 1.2,
                duration: 0.6
            });
        },
        mouseLeave(ev) {
            this.gsap.to(ev.target, {
                scale: 1,
                duration: 0.6
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                drag(
                    this.slideAnimation,
                    true,
                    false,
                    "draggable-slider-terroir-layout2",
                    this.getPadding
                );
            }, 300);
        });
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.08, 0.45, 0, 1);
$ease-in: cubic-bezier(0.17, 0.01, 0.4, 0.99);

.terroirs-slider /deep/ {
    margin: 0 auto;
    width: calc(100% - 390px);
    padding-top: 144px;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        width: calc(100% - 330px);
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        width: calc(100% - 256px);
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        width: calc(100% - 94px);
        padding-top: 89px;
    }
    @media only screen and (max-width: 767px) {
        width: calc(100% - 32px);
        padding-top: 55px;
    }

    .unselectable {
        outline: none;
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .slide-left-terr {
        transform: translateX(25%);
        transition: all 1s 0.1s $ease-in;

        &.observed {
            transform: translateX(0);
        }
    }

    .text-wipe {
        .header {
            color: #0a141c;
            text-align: left;
            font-size: 55px;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                font-size: 34px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }
        }
    }

    .draggable {
        cursor: grab;

        .draggable-slider {
            display: flex;
            align-items: center;
            margin-top: 77px;
            width: fit-content;
            @media only screen and (max-width: 767px) {
                margin-top: 30px;
            }

            .slide-item {
                position: relative;
                transform-origin: bottom;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                outline: 1px solid transparent;
                padding: 10px;
                width: 345px;
                height: 438px;
                margin-left: 32px;

                &:first-child {
                    margin-left: 0;
                }

                @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                    width: 259px;
                    height: 323px;
                }
                @media only screen and (max-width: 1439px) and (min-width: 1366px) {
                    width: 250px;
                    height: 323px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    width: 210px;
                    height: 280px;
                    margin-left: 30px;
                    padding: 7px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    width: 219px;
                    height: 270px;
                    padding: 7px;
                    margin-left: 15px;
                }

                @media only screen and (max-width: 767px) {
                    width: 190px;
                    height: 231px;
                    margin-left: 15px;
                    padding: 6px;
                    margin-left: 15px;
                }

                .image-container {
                    overflow: hidden;
                    clip-path: inset(100% 0% 0% 0%);
                    width: 100%;
                    height: 100%;

                    .item-image {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                    }
                }

                .title {
                    position: absolute;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 34px;
                    color: white;
                    text-decoration: underline;
                    text-decoration-color: #e5b676;
                    cursor: pointer;
                    text-underline-offset: 4.5px;
                    will-change: transform;

                    @media only screen and (max-width: 1440px) and (min-width: 769px) {
                        font-size: 24px;
                    }
                    @media only screen and (max-width: 768px) {
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

.terroirs-slider.dark /deep/ {
    .text-wipe {
        .header {
            color: white !important;
        }
    }
}
</style>
